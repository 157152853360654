/* src/components/FAQ/FAQ.module.css */

.faqContainer {
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .faqSection {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .textContentTitle {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .title {
    font-size: 36px;
    color: #333;
  }
  
  .subtitle {
    font-size: 18px;
    color: #666;
  }
  
  .accordion {
    width: 100%;
  }
  