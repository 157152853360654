/* Homepage.css */

/* Container for the entire homepage */
.Homepage {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Hero Section */
  .HeroActions {
    align-self: stretch;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 160px;
    padding-bottom: 160px;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
  }
  
  /* Text Content within Hero Section */
  .TextContentTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  
  /* Title Styling */
  .Title {
    text-align: center;
    color: #1E1E1E;
    font-size: 72px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    line-height: 86.40px;
    word-wrap: break-word;
  }
  
  /* Subtitle Styling */
  .Subtitle {
    text-align: center;
    color: #757575;
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 38.40px;
    word-wrap: break-word;
  }
  
  /* Button Group Container */
  .ButtonGroup {
    width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  
  /* Generic Button Styling */
  .Button {
    flex: 1 1 0;
    height: 56px;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
  }
  
  /* Specific Button Styles */
  
  /* Light Button */
  .Button--light {
    background: #E3E3E3;
    border: 1px #767676 solid;
    color: #1E1E1E;
  }
  
  /* Dark Button */
  .Button--dark {
    background: #2C2C2C;
    border: 1px #2C2C2C solid;
    color: #F5F5F5;
  }
  
  /* Button Hover Effects */
  .Button--light:hover {
    background: #d5d5d5; /* Optional: Adjust as needed */
  }
  
  .Button--dark:hover {
    background: #1b1b1b; /* Optional: Adjust as needed */
  }