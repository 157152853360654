/* src/components/Help/Help.module.css */

/* Outer Container */
.helpContainer {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  
  /* Main Content Section */
  .mainContent {
    width: 100%;
    max-width: 1307px;
    padding: 160px 24px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
  }
  
  /* Text Content Title */
  .textContentTitle {
    width: 100%;
    max-width: 449px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
  }
  
  .title {
    color: #2c2c2c;
    font-size: 72px;
    font-weight: 700;
    line-height: 86.4px;
    word-wrap: break-word;
  }
  
  .subtitle {
    color: #555555;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }
  
  /* Content Styles */
  .content {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .paragraph {
    color: #1e1e1e;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
  }
  
  .link {
    color: #1e88e5;
    text-decoration: none;
  }
  
  .link:hover,
  .link:focus {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .mainContent {
      padding: 80px 16px;
    }
  
    .title {
      font-size: 48px;
      line-height: 58px;
    }
  
    .subtitle {
      font-size: 20px;
      line-height: 24px;
    }
  
    .paragraph {
      font-size: 16px;
    }
  
    .content {
      max-width: 100%;
    }
  }
  