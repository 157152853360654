/* AcceptRequest.module.css */

/* Outer Container */
.AcceptRequest {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter', sans-serif;
}

/* Content Wrapper */
.ContentWrapper {
  width: 100%;
  max-width: 1285px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Page Product Section */
.PageProduct {
  width: 100%;
  padding: 64px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

/* Section Layout */
.Section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 64px;
  width: 100%;
}

@media (max-width: 768px) {
  .Section {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

/* Image Styling */
.Image {
  flex: 1;
  max-width: 546px;
  width: 100%;
  height: 383px;
  border-radius: 8px;
  object-fit: cover;
}

/* Column Layout */
.Column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Body Section */
.Body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Title Section */
.Title {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Text Heading */
.TextHeading {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

/* Event Name */
.EventName {
  color: #1e1e1e;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
}

/* Event Date */
.EventDate {
  color: #555555;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 8px;
}

/* Price Section */
.Price {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
}

/* Price Amount */
.PriceAmount {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 700;
}

/* Text Description */
.Text {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

/* Buyer Email Display */
.BuyerEmail {
  margin-top: 8px;
  font-size: 16px;
  color: #2c2c2c;
  word-break: break-all;
}

/* Action Button */
.ActionButton {
  background: #2c2c2c;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background 0.3s;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  width: auto;
}

.ActionButton:hover {
  background-color: #444;
}

.ActionButton:disabled {
  background: #d9d9d9;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Accordion Styles */
.Accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.AccordionItem {
  padding: 16px;
  background: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.AccordionTitle .Title {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 600;
}

.AccordionContent .Body {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 8px;
}

/* Card Grid Pricing Section */
.CardGridPricing {
  width: 100%;
  padding: 64px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
}

.CardGrid {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 32px;
}

@media (max-width: 768px) {
  .CardGrid {
    flex-direction: column;
    align-items: center;
  }
}

/* Pricing Card */
.PricingCard {
  flex: 1;
  padding: 32px;
  background: white;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

/* Card Header */
.CardHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Step Number */
.StepNumber {
  background: #02542d;
  color: #fff;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
}

/* Step Title */
.StepTitle {
  margin-top: 12px;
  color: #1e1e1e;
  font-size: 22px;
  font-weight: 600;
}

/* Text List within Pricing Card */
.TextList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.TextListItem {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

/* Status Message */
.StatusMessage {
  margin-top: 10px;
  color: #1e1e1e;
  text-align: center;
  font-size: 14px;
}

/* Popup Styles */
.Popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PopupContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.CloseButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2c2c2c;
  color: #f5f5f5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.Spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #2c2c2c;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 0 auto 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .ActionButton {
    width: 100%;
    justify-content: center;
  }

  .Image {
    max-width: 100%;
    height: auto;
  }

  .ContentWrapper {
    padding: 16px;
  }

  .PageProduct {
    padding: 32px;
  }

  .CardGridPricing {
    padding: 32px;
  }

  .PricingCard {
    width: 100%;
  }
}
