/* Footer.css */

.footer {
    background-color: #222;
    color: #fff;
    padding: 40px 20px 20px 20px;
    font-family: Arial, sans-serif;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1 1 200px;
    margin: 10px;
  }
  
  .footer-section h4 {
    margin-bottom: 15px;
    font-size: 18px;
    border-bottom: 2px solid #555;
    padding-bottom: 5px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section li {
    margin-bottom: 10px;
  }
  
  .footer-link {
    color: #bbb;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  
  .footer-link:hover {
    color: #fff;
    text-decoration: underline;
  }
  
  .footer-icon {
    margin-right: 8px;
    font-size: 18px;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
    border-top: 1px solid #555;
    padding-top: 20px;
    font-size: 14px;
    color: #aaa;
  }
  
  @media (max-width: 600px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin: 20px 0;
    }
  }
  