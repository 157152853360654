/* LinkScreen.module.css */

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    font-family: 'Inter', sans-serif;
  }
  
  .title {
    color: #333;
    font-size: 36px;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
  }
  
  .notification {
    width: 100%;
    max-width: 600px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .notificationIcon {
    flex-shrink: 0;
    margin-right: 16px;
    color: #4caf50;
    font-size: 24px;
  }
  
  .notificationContent {
    flex: 1;
  }
  
  .notificationTitle {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .notificationBody {
    color: #555;
    font-size: 16px;
    margin-bottom: 12px;
  }
  
  .details {
    background: #f1f1f1;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
    word-break: break-all;
  }
  
  .button {
    background: #2c2c2c;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
    margin-top: 20px;
  }
  
  .button:hover {
    background: #444;
  }
  
  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #999;
    transition: color 0.3s;
  }
  
  .closeButton:hover {
    color: #666;
  }
  
  @media (max-width: 768px) {
    .notification {
      flex-direction: column;
      align-items: center;
    }
  
    .notificationIcon {
      margin-bottom: 12px;
    }
  
    .title {
      font-size: 28px;
    }
  }
  