/* src/components/AboutUs/AboutUs.module.css */

/* Outer Container */
.aboutUsContainer {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* Individual Listing Block */
.individualListing {
  width: 100%;
  max-width: 1307px;
  padding: 160px 24px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  box-sizing: border-box;
}

/* Text Content Title */
.textContentTitle {
  width: 100%;
  max-width: 449px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.title {
  color: #2c2c2c;
  font-size: 72px;
  font-weight: 700;
  line-height: 86.4px;
  word-wrap: break-word;
}

.subtitle {
  color: #555555;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

/* Accordion Styles */
.accordion {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .individualListing {
    padding: 80px 16px;
  }

  .title {
    font-size: 48px;
    line-height: 58px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .accordion {
    max-width: 100%;
  }
  .content {
    max-width: 100%;
  }
}
