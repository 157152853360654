/* PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 32px;
    color: #222;
  }
  
  .privacy-policy-container h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 24px;
    color: #444;
  }
  
  .privacy-policy-container h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #555;
  }
  
  .privacy-policy-container p {
    margin-bottom: 15px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .privacy-policy-container a {
    color: #1a0dab;
    text-decoration: none;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  