/* RequestATicket.css */

.RequestATicket {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto; /* Centers the container */
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RequestATicketTitle {
  color: #1E1E1E;
  font-size: 48px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  margin-top: 32px;
  text-align: center; /* Ensures the title text is centered */
}

.IndividualListing {
  width: 100%;
  padding: 120px 30px;
  background: #F5F5F5;
  display: flex;
  margin: 0 auto; /* Centers the container */
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.FormContact {
  width: 100%;
  max-width: 449px;
  padding: 24px;
  background: white;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.TextContentTitle {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.InputLabelLarge {
  color: #2C2C2C;
  font-size: 24px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  line-height: 32px;
}

.InputFieldLarge {
  width: 100%;
  padding: 12px 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #1E1E1E;
}

.InputField {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.InputLabel {
  color: #1E1E1E;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 22.4px;
}

.InputFieldSmall {
  width: 90%;
  padding: 12px 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #1E1E1E;
}

.ReadOnlyField {
  background: #F0F0F0; /* Optional: change background to indicate read-only */
  color: #7A7A7A; /* Optional: change text color to indicate read-only */
}

.ButtonGroup-request-ticket {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px; /* Add gap between buttons */
}

.Button-request-ticket {
  flex: 1; /* Make buttons take equal space */
  padding: 12px;
  background: #2C2C2C;
  border-radius: 8px;
  border: 1px solid #2C2C2C;
  color: #F5F5F5;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  cursor: pointer;
}

/* Popup styles */
.Popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the popup is above other elements */
}

.PopupContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.CloseButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2C2C2C;
  color: #F5F5F5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Spinner styles */
.Spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #2C2C2C; /* Dark grey */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 0 auto 20px auto; /* Center the spinner and add margin at the bottom */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Event Input Container */
.EventInputContainer {
  position: relative;
}

/* Suggestions list */
.SuggestionsList {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #D9D9D9;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.SuggestionItem {
  padding: 8px;
  cursor: pointer;
}

.SuggestionItem:hover {
  background-color: #F5F5F5;
}

.EventImageContainer {
  text-align: center;
  margin: 20px 0;
}

.EventImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.SeatMapContainer {
  text-align: center;
  margin: 20px 0;
}

.SeatMapImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
