/* Header.css */

/* Reset default margins and paddings for consistency */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Header Wrapper */
.header {
    width: 100%;
    padding: 16px 32px;
    background: white;
    border-bottom: 1px solid #D9D9D9;
    position: fixed; /* Makes the header sticky at the top */
    top: 0;
    left: 0;
    z-index: 1000; /* Ensures the header stays above other elements */
    display: flex;
    justify-content: center; /* Centers the inner container */
}
.logo-image {
    width: 100px; /* Adjust the width as needed */
    height: auto; /* Maintains the aspect ratio */
  }
/* Inner Container */
.header-container {
    max-width: 1200px; /* Adjust as needed */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Spaces out the two main sections */
}

/* Logo Section */
.logo {
    display: flex;
    align-items: center;
    gap: 16px;
}

.logo-icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 23.33px;
    height: 35px;
    border: 3.5px solid #1E1E1E;
    border-radius: 4px;
}

/* Buttons Section */
.buttons-section {
    display: flex;
    align-items: center;
    gap: 24px; /* Increased gap for better spacing */
}

/* Navigation Links */
.navigation-pills {
    display: flex;
    align-items: center;
    gap: 16px;
}

.navigation-pill {
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s;
}

.navigation-pill:hover {
    background: #D9D9D9;
}

.title {
    color: #1E1E1E;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
}

/* Authentication Section */
.header-auth {
    display: flex;
    align-items: center;
    gap: 12px;
}

.auth-button {
    height: 32px;
    padding: 8px 16px;
    background: #E3E3E3;
    border: 1px solid #767676;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    text-decoration: none;
}

.auth-button:hover {
    background: #D4D4D4;
}

.button-text {
    color: #1E1E1E;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
}

/* User Profile Image */

/* Email Container */
.email-container {
    position: relative;
    display: inline-block;
}

/* Image Wrapper */
.image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/* Profile Image */
.profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
    cursor: pointer; /* Indicates interactivity */
}

/* Email Tooltip */
.email-tooltip {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background-color: #838383;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 6px 8px;
    position: absolute;
    top: 100%; /* Position below the image */
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    white-space: nowrap;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    z-index: 1;
}

/* Show the email on hover or focus */
.email-container:hover .email-tooltip,
.email-container:focus-within .email-tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}

/* Hamburger Menu Icon */
.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

.no-text-decoration {
    text-decoration: none;
}

/* Change cursor to pointer */
.cursor-pointer {
    cursor: pointer;
}

/* Mobile Navigation */
@media (max-width: 768px) {
    .navigation-pills {
        display: none; /* Hide navigation links on small screens */
    }

    .hamburger {
        display: block; /* Show hamburger menu on small screens */
    }

    .mobile-navigation {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 16px 0;
        background: white;
        border-top: 1px solid #D9D9D9;
        position: absolute;
        top: 64px; /* Adjust based on header height */
        left: 0;
        width: 100%;
        z-index: 999;
    }
}

/* Error Message Styling (if needed) */
.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

/* Active Navigation Pill Styling */
.navigation-pill.active {
    background: #E3E3E3; /* Highlight background color */
}

.navigation-pill.active .title {
    color: #000000;       /* Text color for active pill */
}