/* src/components/AccordionItem.module.css */

.accordionItem {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #fff;
  }
 /* src/components/AccordionItem.module.css */

.contentText {
  white-space: pre-wrap; /* Preserves line breaks */
  font-weight: normal;   /* Ensures normal font weight */
}

.contentText strong {
  font-weight: bold;     /* Bolds text wrapped in **double asterisks** */
}
/* src/components/AccordionItem.module.css */

.contentText {
  white-space: pre-wrap;
}

.contentText::before,
.contentText::after {
  content: '';
}

.contentText {
  font-weight: normal;
}

.contentText strong {
  font-weight: bold;
} 
  .accordionButton {
    width: 100%;
    padding: 16px;
    background-color: #f0f0f0;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .accordionButton:hover,
  .accordionButton:focus {
    background-color: #e0e0e0;
  }
  
  .icon {
    font-size: 20px;
    transition: transform 0.3s ease;
  }
  
  .rotate {
    transform: rotate(180deg);
  }
  
  .accordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: #fafafa;
  }
  
  .accordionContent p {
    padding: 16px;
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  /* New Styles for Lists */
  .accordionContent ul,
  .accordionContent ol {
    padding-left: 40px; /* Increased left padding for better indentation */
    margin: 16px 0;
  }
  
  .accordionContent ul li,
  .accordionContent ol li {
    margin-bottom: 10px;
  }
  