/* YourTransactions.css */

/* Outer Container */
.YourTransactions {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Inter', sans-serif;
}

/* Title */
.your-transactions-title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

/* Rounded Tabs */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  background-color: #f1f1f1;
  border-radius: 50px;
  padding: 5px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.tab {
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 600;
  color: #666;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s;
}

.tab.active {
  background-color: #1e88e5;
  color: #fff;
}

.tab:hover {
  color: #333;
}

/* Listings Container */
.listings-container {
  display: flex;
  flex-direction: column;
}

/* Section */
.section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 24px;
  font-weight: 600;
  color: #444;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Card Grid */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

/* Listing Card */
.listing-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listing-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.listing-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.listing-event-name {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 15px;
  margin-bottom: 5px;
}

/* Event Date */
.listing-event-date {
  font-size: 14px;
  color: #555;
  margin: 0 15px 10px;
}

/* Listing Details */
.listing-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 15px;
}

.listing-price {
  font-size: 18px;
  font-weight: 500;
  color: #000; /* Changed to black */
}

.listing-status {
  font-size: 14px;
  font-weight: 500;
  color: #000; /* Changed to black for in-progress */
}

.listing-status.completed {
  color: #43a047; /* Green color for completed status */
}

/* Link Styling for Listing Cards in Selling Tab */
.listing-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}

.listing-card-link:hover .listing-card {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

/* No Listings Message */
.no-listings {
  font-size: 16px;
  color: #777;
  text-align: center;
  margin-top: 20px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 30px 40px;
  border-radius: 10px;
  position: relative;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Modal Close Button */
.modal-close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 28px;
  cursor: pointer;
}

/* Modal Body */
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-size: 28px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

/* Modal Event Date */
.modal-event-date {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.modal-text {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.modal-link {
  font-size: 14px;
  color: #1e88e5;
  word-break: break-all;
  margin-bottom: 20px;
  text-decoration: none;
}

.modal-link:hover {
  text-decoration: underline;
}

/* Modal Copy Button */
.modal-copy-button {
  background: #1e88e5;
  color: #fff;
  padding: 12px 25px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;
}

.modal-copy-button:hover {
  background: #1565c0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .your-transactions-title {
    font-size: 28px;
  }

  .tab {
    padding: 8px 20px;
    font-size: 16px;
  }

  .section-title {
    font-size: 20px;
  }

  .listing-event-name {
    font-size: 18px;
  }

  .listing-event-date {
    font-size: 13px;
  }

  .listing-price {
    font-size: 16px;
  }

  .listing-status {
    font-size: 12px;
  }

  .modal-content {
    padding: 20px 30px;
  }

  .modal-title {
    font-size: 24px;
  }

  .modal-copy-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
